<template>
  <div>
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Reestablecer Contraseña</div>
      <a-form-model
        v-show="!sendEmail"
        class="mb-4"
        ref="rule-Form"
        :model="form"
        :rules="rules"
        layout="vertical"
        :wrapper-col="{ span: 24}"
      >
        <a-form-model-item ref="email"  prop="email" >
          <a-input v-model.trim="form.email" size="large" disabled/>
        </a-form-model-item>
        <a-form-model-item ref="password"  prop="password" >
          <a-input v-model.trim="form.password"
                   type="password"
                   size="large"
                   placeholder="Nueva Contraseña"/>
        </a-form-model-item>
        <a-form-model-item ref="password_confirmation" prop="password_confirmation" >
          <a-input v-model.trim="form.password_confirmation"
                   type="password"
                   size="large"
                   placeholder="Nueva Contraseña"/>
        </a-form-model-item>
        <div class="row justify-content-center">
          <a-button
            type="primary"
            htmlType="submit"
            size="large"
            class="text-center w-100"
            :loading="loading"
            @click="onSubmit"
          >
            <strong>Reestablecer Contraseña</strong>
          </a-button>
        </div>
      </a-form-model>

      <a-alert
        class="my-4"
        v-show="sendEmail"
        :message="messageResponse.message"
        :description="messageResponse.description"
        :type="messageResponse.type"
        show-icon
      />
      <a-button
        v-show="sendEmail"
        type="default"
        htmlType="submit"
        size="large"
        class="text-center w-100 mb-4"
        :loading="loading"
        @click="reset"
      >
        <strong>Volver a intentar</strong>
      </a-button>
      <router-link to="/auth/login" class="kit__utils__link font-size-16">
        <i class="fe fe-arrow-left mr-1 align-middle" />
        Acceder
      </router-link>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
export default {
  name: 'resetPassword',
  data: function () {
    return {
      loading: false,
      sendEmail: false,
      messageResponse: {
        message: '',
        description: '',
        type: 'info',
      },
      form: {
        token: this.$route.params.pathMatch,
        email: this.$route.query.email,
        password: '',
        password_confirmation: '',
      },
      rules: {
        email: [
          { required: true, message: 'El Correo Electrónico es obligatorio.', trigger: 'blur' },
          { type: 'email', message: 'El Correo Electrónico no es válido.', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'La contraseña es obligatoria.', trigger: 'blur' },
          { type: 'string', message: 'La contraseña no es válida.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
        ],
        password_confirmation: [
          { required: true, message: 'El campo Contraseña es obligatorio.', trigger: 'blur' },
          { type: 'string', message: 'El campo Contraseña no es válido.', trigger: 'blur' },
          { min: 8, message: 'La contraseña debe contener al menos 8 caracteres', trigger: 'blur' },
          {
            validator: () => {
              if (this.form.password === this.form.password_confirmation) {
                return true
              } else {
                return false
              }
            },
            message: 'Las Contraseñas no coinciden.',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    anErrorHasOcurred() {
      this.sendEmail = true
      this.messageResponse.message = '¡Ups!'
      this.messageResponse.description = 'La contraseña no es válida.'
      this.messageResponse.type = 'warning'
    },
    reset() {
      this.loading = false
      this.sendEmail = false
      this.messageResponse = {
        message: '',
        description: '',
        type: 'info',
      }
      this.form = {
        password: '',
        password_confirmation: '',
      }
    },
    onSubmit() {
      this.$refs['rule-Form'].validate(valid => {
        if (valid) {
          this.loading = true
          apiClient.post('/reset-password', { ...this.form }).then(res => {
            this.loading = false
            this.sendEmail = true
            this.messageResponse.message = '¡Listo!'
            this.messageResponse.description = 'La contraseña ha sido reestablecida.'
            this.messageResponse.type = 'info'
          }).catch(() => {
            this.loading = false
            this.sendEmail = true
            this.messageResponse.message = '¡Ups!'
            this.messageResponse.description = 'Ha ocurrido un error.'
            this.messageResponse.type = 'error'
          })
        } else {
          return false
        }
      })
    },
  },
  mounted() {
    // console.log(this.token, this.email)
    // console.log(this.form)
  },
}
</script>

<style lang="scss" module>
  @import "@/@airui/system/Auth/style.module.scss";
</style>
